/** @jsx jsx */
import { jsx } from "theme-ui";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

export default function PostFooter({ date, styles = defaultStyles }) {
  return (
    <p sx={styles}>
      <span>{dayjs(date).format("MMMM Do, YYYY")}</span>
    </p>
  );
}

const defaultStyles = {
  borderTop: "solid 1px",
  fontSize: ".8em",
  paddingTop: "5px",
};
