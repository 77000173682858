/** @jsx jsx */
import { Link } from "gatsby";
import { jsx } from "theme-ui";
import validator from "validator";
import StyledLink from "../../components/StyledLink";

export default (props) => {
  const url = props.mark.href;
  const isValidURL = validator.isURL(url, { require_protocol: true });

  if (isValidURL) {
    return (
      <StyledLink as="a" external href={url}>
        {props.children}
      </StyledLink>
    );
  } else {
    return (
      <StyledLink as={Link} to={`/${url}`}>
        {props.children}
      </StyledLink>
    );
  }
};
