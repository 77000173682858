/** @jsx jsx */
import { jsx } from "theme-ui";
import BlockContent from "@sanity/block-content-to-react";
import serializer from "../../utils/serializer";

export default function ListedPost({ blocks, serializers = serializer }) {
  return (
    <div>
      <BlockContent blocks={blocks} serializers={serializers} />
    </div>
  );
}
