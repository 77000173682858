/** @jsx jsx */
import { jsx } from "theme-ui";

export default function ListedPost({ styles = defaultStyles, children }) {
  return <article sx={styles}>{children}</article>;
}

const defaultStyles = {
  paddingTop: "30px",
  paddingBottom: "35px",
};
