/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import BlockContent from "@sanity/block-content-to-react";

export default (props) => {
  const { style = "normal" } = props.node;

  const components = {
    h2: Styled.h2,
    h3: Styled.h3,
    h4: Styled.h4,
    h5: Styled.h5,
    h6: Styled.h6,
    blockquote: Styled.blockquote,
    normal: Styled.p,
  };

  if (style in components) {
    const StyledHTML = components[style];
    return <StyledHTML>{props.children}</StyledHTML>;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};
