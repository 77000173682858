import React from "react";
import Article from "./PostContainer";
import Title from "./PostTitle";
import Content from "./PostContent";
import Footer from "./PostFooter";

export default function ListedPost({ post }) {
  return (
    <Article>
      <Title slug={post.slug.current} title={post.title} />
      <Content blocks={post._rawBody} />
      <Content blocks={post._rawFootnotes} />
      <Footer date={post.publishedAt} />
    </Article>
  );
}
