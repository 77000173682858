/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { Link } from "gatsby";
import StyledLink from "../StyledLink";

export default function ListedPost({ slug, title, styles = defaultStyles }) {
  return (
    <Styled.h1>
      <StyledLink as={Link} styles={styles} to={`/${slug}`}>
        {title}
      </StyledLink>
    </Styled.h1>
  );
}

const defaultStyles = {
  color: "text",
  transitionDuration: ".3s",
  textDecoration: "none",
  ":visited": {
    color: "text",
  },
  ":hover": {
    color: "primary",
    transitionDuration: ".3s",
  },
};
