import Block from "./Block";
import Code from "./Code";
import Link from "./Link";

export default {
  types: {
    code: Code,
    block: Block,
  },
  marks: {
    link: Link,
  },
};
